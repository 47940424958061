// 404 Page

// _____________________________________________________________

// Libraries
import React from 'react'

// Elements
import { Box } from '../components/ui'

// Theme
import theme from '../gatsby-plugin-theme-ui'

// _____________________________________________________________

const fourofourPage = () => {
  return <Box>404</Box>
}

export default fourofourPage

// _____________________________________________________________
